.table-call-log-compare {
    table-layout: fixed;
}
.table-call-log-compare td {
    vertical-align: top;
    padding: 20px 0;
    overflow: hidden;
}
.table-call-log-compare th {
    width: 50%;
}
.table-call-log-compare td.call-wrap-1 {
    padding-right: 20px;
}
.call-log-compare-options {
    display: flex;
}
.call-log-compare-options > * {
    margin: 0 15px 0 0;
    vertical-align: middle;
    display: inline-block;
}