.sip-success {
    color: green;
}
.sip-warn {
    color: maroon;
}
.sip-warn-msg {
    margin: 15px 0;
}
.call-status-list {
    list-style: none;
    padding: 0;
}
.call-status-list li {
    margin-bottom: 10px;
}
.sip-summary {
    display: flex;
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 1px dotted black;
}
.sip-summary > span {
    text-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.core-text-summary {
    flex-grow: 1;
    margin: 0 10px;
}
.core-text-summary:hover {
    color: #888;
}
.sip-toggle-icon {
    float: right;
}
.sip-summary--open {
    border-bottom: none;
}
.mb-error-pre {
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    white-space: normal;
}
